<template>
  <div style="width: 100%;">
    <b-container style="width: 100%;">
      <b-row style="width: 100%;">
        <b-dropdown
          style="width: 100%;"
          split
          split-variant="outline-primary"
          variant="primary"
          :text="selectedItem"
        >
          <b-dropdown-item
            v-for="item in warehouses"
            :key="item"
            @click="emitWarehouse(item)"
          >{{ item
          }}</b-dropdown-item>

        </b-dropdown>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getUserWarehouses } from '../service'

export default {
  name: 'UserWarehouses',

  props: {
    defaultData: String,
  },
  data() {
    return {
      warehouses: [],
      selectedItem: this.getStoredWarehouse() || 'Select Warehouse',
    }
  },
  watch() {

  },
  created() {
    this.getWarehouses()
    if (this.getStoredWarehouse()) {
      this.emitWarehouse(this.getStoredWarehouse())
    }
  },

  methods: {
    getStoredWarehouse() {
      return localStorage.getItem(this.getUserSession().user)
    },
    getLevenshteinDistance(a, b) {
      if (a.length === 0) return b.length
      if (b.length === 0) return a.length

      const matrix = []

      for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i]
      }

      for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j
      }

      for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
          if (b.charAt(i - 1) === a.charAt(j - 1)) {
            matrix[i][j] = matrix[i - 1][j - 1]
          } else {
            matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1))
          }
        }
      }

      return matrix[b.length][a.length]
    },

    orderArrayByLikeness(arr, target) {
      return arr.sort((a, b) => getLevenshteinDistance(a, target) - getLevenshteinDistance(b, target))
    },
    emitWarehouse(warehouse) {
      this.selectedItem = warehouse
      this.$emit('warehouse', warehouse)
      window.localStorage.setItem(this.getUserSession().user_email, warehouse)
    },
    getWarehouses() {
      getUserWarehouses().then(response => {
        console.log('response in warehouses')
        this.warehouses = response.map(item => item.for_value)
        const storedWarehouse = this.getStoredWarehouse()
        console.log(storedWarehouse)
        console.log(this.warehouses)
        this.warehouses.forEach((item_, index_) => {
          console.log('-------------warehoses------------')
          console.log(item_)
        })
        if (storedWarehouse) {
          this.warehouses = this.orderArrayByLikeness(this.warehouses, storedWarehouse)
        }

        if (this.defaultData && this.warehouses.length) {
          if (this.warehouses.includes(this.defaultData)) {
            this.selectedItem = this.defaultData
            this.emitWarehouse(this.defaultData)
          }
        }
        if (this.warehouses.length) {
          const first = this.warehouses[0]
          this.emitWarehouse(first)
          this.selectedItem = first
        }
      })
    },
  },

}
</script>

<style></style>
