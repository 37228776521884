<template>
  <div>
    <b-button
      class="pull-right"
      variant="primary btn-sm"
      @click="inpatientAppointment()"
    >
      Create Inpatient Appointment
    </b-button>
  </div>
</template>

<script>
import {
  createInpatientAppointment,
} from '../service'

export default {
  name: 'CreateInpatientAppointment',
  props: {
    inp: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {
    inpatientAppointment() {
      createInpatientAppointment({
        inp: this.inp,
      }).then(data => {
        //   frappe.msgprint({
        //   title: ("Notification"),
        //   indicator: "green",
        //   message: ("Appointment successfully created"),
        // });
      })
    },

  },
}
</script>

<style></style>
