import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getItemPrice = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.item_price.get_price',
  args: { payload },
})

export const createChargeSheet = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.execute',
  args: { payload },
})

export const updateChargeSheet = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.update_item.update_item_from_charge_sheet',
  args: { payload },
})

export const chargeSheetAddItem = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.add_to_charge_sheet',
  args: { payload },
  freeze: false,
})

export const chargeSheetAddItemReturn = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.returns.add_to_charge_sheet_return',
  args: { payload },
  freeze: false,
})

export const chargeSheetCancelReturn = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.returns.cancel_return',
  args: { payload },
  freeze: false,
})

export const chargeSheetMigrate = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.swap_charge_sheet.change_parent',
  args: { payload },
  freeze: false,
})

export const chargeSheetInvoiceMigrate = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.migrate.execute',
  args: { payload },
  freeze: false,
})

export const chargeSheetMarkAsIncomplete = payload => api({
  method: 'billing.billing.api.charge_sheet.actions.migrate.reprocess',
  args: { payload },
  freeze: false,
})

export const chargeSheetRemoveItem = name => api({
  method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.remove_item_from_charge_sheet',
  args: { name },
})

export const checkChargeSheet = patient => api({
  method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.check_if_exits',
  args: { patient },
})

export const chargeSheetSubmit = name => api({
  method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.submit_chargesheet',
  args: { name },
  freeze: false,
})

export const chargeSheetDelete = name => api({
  method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.delete_chargesheet',
  args: { name },
  freeze: false,
})

export const getUserWarehouses = () => api({
  method: 'billing.billing.api.accounts.customer.get_user_permitted_warehouses',
  args: {},
})

export const checkOpenAppointment = patient => api({
  method: 'clinical.api.patient_encounter.patient_encounter.check_open_appointment',
  args: {
    patient,
  },
})

export const createInpatientAppointment = ({ inp }) => api({
  method: 'billing.billing.api.patient.inpatient_standing_charges_billing.create_apps_for_single_admitted_patient_with_no_apps',
  args: {
    inp,
  },
})
