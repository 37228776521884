<template>
  <div :key="refreshKey" class="w-100">
    <div
      v-if="Component_Loading"
      style="width:100%"
      class="text-center"
    >
      <b-spinner
        variant="secondary"
        label="Spinning"
      />
    </div>
    <b-row class="mt-1 mb-1">
      <b-col>
        <div class="d-flex flex-column mb-6 bg-surface-variant">
          <v-sheet class="ma-2 pa-2">
            <b-alert
              v-model="showDismissibleAlert"
              variant="info"
              dismissible
            >
              {{ response_message }}
            </b-alert>

            <b-alert
              v-model="showDismissibleAlertSuccess"
              variant="success"
              dismissible
            >
              {{ response_message }}
            </b-alert>

            <b-alert
              v-model="showDismissibleAlertError"
              variant="danger"
              dismissible
            >
              {{ response_message }}
            </b-alert>
          </v-sheet>
        </div>
      </b-col>
    </b-row>

    <!-- a modal for services updated -->
    <b-modal
      v-model="Add_Service_Consumable_Dialog"
      size="xl"
      :title="Modal_Heading == Bill_services_String ? Modal_Heading : Modal_Heading + ' - ' + warehouse"
      hide-footer
      class="container mt-5"
      :modal-class="'custom-modal-width'"
      @hide="resetBillingDialog"
    >
      <b-row>
        <b-col
          :class="prescriptions.length > 0 && Modal_Heading == Bill_Consumables_String ? 'col-12 col-md-7' : 'col-12'"
        >
          <div class="container">
            <b-form-group
              :label="Modal_Heading == Bill_services_String ? 'Select/Search A Service To Bill' : 'Select/Search A Consumable To Bill'"
              label-for="input"
              invalid-feedback="Required"
            >
              <v-select
                ref="billingSelect"
                v-model="selected_Service"
                :options="paginated"
                :filterable="false"
                :placeholder="Modal_Heading == Bill_services_String ? 'Select/Search A Service To Continue' : 'Select A Consumable To Continue'"
                :searchable="true"
                @search="onSearch"
                @input="onChangeService($event)"
              >

                <template #option="{ Item_Name, Item_Code, Selling_Price, Description }">
                  <div style="padding-bottom: 10px;">
                    <h5 style="margin: 0">
                      <b> {{ Item_Name }} </b> - Price: <b> {{ Selling_Price }} </b>
                    </h5>
                    <em>( <b style="color: rgb(110, 110, 110);"> {{ Item_Code }} </b> ) Description: {{ Description
                    }}</em>

                  </div>
                  <!-- <hr style="color: white;" /> -->
                </template>

                <template #selected-option="{ Item_Name, Item_Code, Selling_Price }">
                  <div style="display: flex; align-items: baseline">
                    <strong>{{ Item_Name }}</strong>
                    <em style="margin-left: 0.5rem"> <b style="color: rgb(131, 131, 131);"> {{ Item_Code }} </b> <b
                      style="margin-left: 5px;"
                    > {{ Selling_Price }}</b></em>
                  </div>
                </template>

                <template #no-options="{ search, searching, loading }">
                  {{ Modal_Heading == Bill_services_String ? 'No Service(s) Found' : 'No Consumables Found' }}
                </template>

                <template #list-footer>
                  <li
                    v-if="total_page > 0"
                    style="text-align: center"
                  >
                    Page {{ computed_page }} / {{ total_page }}
                  </li>
                  <li
                    v-if="total_page > 1"
                    slot="list-footer"
                    class="pagination"
                  >
                    <b-button
                      size="sm"
                      squared
                      :disabled="!hasPrevPage"
                      @click="offset -= limit"
                    >
                      Prev
                    </b-button>
                    <b-button
                      size="sm"
                      squared
                      :disabled="!hasNextPage"
                      @click="offset += limit"
                    >
                      Next
                    </b-button>
                  </li>
                  <li
                    v-if="computed_page == total_page"
                    style="text-align: center; color: rgb(166, 166, 166)"
                  >{{
                    Modal_Heading == Bill_services_String ? 'End of Services!' : 'End of Consumables' }} </li>
                </template>

              </v-select>

            </b-form-group>

            <b-form-group
              v-if="selected_Service.Item_Code"
              label="Set Quantity"
              label-for="input"
              invalid-feedback="Required"
            >
              <b-form-input
                v-if="selected_Service.Item_Code"
                v-model="Service_amount"
                type="number"
                placeholder="Enter quantity"
                min="0"
                step="1"
                @input="restrictNumericInput"
              />
            </b-form-group>

            <span
              v-if="selected_Service.Item_Code"
              style=" margin-top=5px; "
            ><b> Item : </b> </span>
            <p v-if="selected_Service.Item_Code"><b>{{ selected_Service.Item_Name }}</b> <b
              style="color: rgb(131, 131, 131);"
            > - {{ selected_Service.Item_Code }}</b> </p>
            <span
              v-if="selected_Service.Selling_Price"
              style=" margin-top=2px; "
            ><b> Price: </b> </span>
            <p> <strong> {{ selected_Service.Selling_Price }} </strong></p>
            <span
              v-if="selected_Service.Item_Group"
              style=" margin-top=2px; "
            ><b> Group: </b> </span>
            <p> {{ selected_Service.Item_Group }}</p>
            <span
              v-if="selected_Service.Description"
              style=" margin-top=2px; "
            ><b> Description: </b> </span>
            <p>{{ selected_Service.Description }}</p>
          </div>
        </b-col>

        <b-col
          v-if="prescriptions.length > 0 && Modal_Heading == Bill_Consumables_String && this.hasRole('Pharmacist')"
          class="col-12 col-md-5"
        >
          <b-card
            v-if="Modal_Heading == Bill_Consumables_String"
            style=" margin-bottom: 5px;"
            :title="'Doctors Prescription - ' + formatDate(prescriptions[0].start_date)"
            header-tag="header"
            footer-tag="footer"
          >
            <template #header>
              <h6 class="mb-0">
                Latest Prescriptions
              </h6>
            </template>
            <div style="max-height: 300px; overflow-y: auto;">
              <b-table
                :items="prescriptions"
                :fields="prescription_fields"
              >

                <template #cell(action)="row">
                  <div class="d-flex justify-content-between align-items-center">
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="filter_prescription(row.item)"
                    >
                      Find
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      style="margin-left: 2px;"
                      @click="printPrescription(row.item)"
                    >
                      <i
                        class="fa fa-print"
                        aria-hidden="true"
                      />
                    </b-button>
                  </div>

                </template>

                <template #cell(start_date)="row">
                  {{ formatDate(row.item.start_date) }}
                </template>

                <template #cell(status)="row">
                  <h4><b-badge variant="warning">
                    {{ row.item.status }}
                  </b-badge></h4>

                </template>

              </b-table>
            </div>
          </b-card>

        </b-col>

      </b-row>

      <b-row>
        <b-col class="col-6 col-md-3">
          <b-card
            v-if="selected_Service.Item_Code && Modal_Heading == Bill_Consumables_String"
            :class="{ 'shake': isShaking }"
            style=" margin-bottom: 5px;"
            :title="'Stock Tracker'"
            header-tag="header"
            footer-tag="footer"
          >
            <template #header>
              <h6 class="mb-0">
                Stock Tracker {{ warehouse }}
              </h6>
            </template>
            <hr style="width: 100%">
            <b-card-text><b style="color: rgb(0, 0, 0);">Current Actual Stock: <span
              :style="stock_in_warehouse == 0 ? 'color: rgb(237, 0, 0);' : 'color: rgb(72, 179, 0);'"
            >{{
              stock_in_warehouse }}</span> </b> </b-card-text>
            <b-card-text><b style="color: grey;">Currently Stocks Being Billed: {{ currrent_billed_stock }} </b>
            </b-card-text>
            <b-card-text><b style="color: rgb(184, 184, 184);">Net Current Stock: <span>{{ net_stock_in_warehouse
            }}</span> </b> </b-card-text>
            <b-button
              size="sm"
              variant="primary"
              @click="check_stocks()"
            >
              Refresh
            </b-button>
            <template #footer>
              <em>Accounts Billing</em>
            </template>
          </b-card>
        </b-col>
        <!-- <b-col class="col-6 col-md-9" >
          <b-card  v-if="prescription_set && Modal_Heading == Bill_Consumables_String"  style=" margin-bottom: 5px;" :title="'Doctors Prescription' " header-tag="header" footer-tag="footer">
            <template #header>
              <h6 class="mb-0">Latest Prescriptions</h6>
            </template>
              <div style="max-height: 300px; overflow-y: auto;">
                <b-table :items="prescriptions" :fields="prescription_fields" >

                  <template #cell(start_date)="row">
                      {{  formatDate(row.item.start_date)  }}
                  </template>

                  <template #cell(status)="row">
                    <h4><b-badge  variant="warning"> {{ row.item.status }} </b-badge></h4>
                  </template>

                </b-table>
              </div>
          </b-card>
        </b-col> -->
      </b-row>

      <div />

      <b-button
        size="sm"
        :disabled="selected_Service.Selling_Price_Float < 0.00 || selected_Service.Selling_Price_Float == undefined || selected_Service.Selling_Price_Float == null || Service_amount == 0 || !selected_Service.Item_Code || adding_item_in_progress"
        block
        variant="outline-success"
        @click="add_item_charge_sheet"
      >{{ adding_item_in_progress ? 'Please Wait..':'Confirm' }}</b-button>
      <b-button
        size="sm"
        variant="outline-secondary"
        block
        @click="Add_Service_Consumable_Dialog = false; Service_amount = 1;"
      >Close</b-button>
    </b-modal>

    <b-row
      v-if="inpatientRecord && !patientHasAppointment"
      style="margin-top:10px;"
    >
      <b-col>
        <create-inpatient-appointment :inp="inpatientRecord" />
      </b-col>
    </b-row>
    <b-container>
      <b-row
        v-if="!patientHasAppointment"
        style="margin-top:10px;"
      >
        <b-col>
          <b-alert
            variant="danger"
            show
          >
            Ensure the patient has an appointment before proceeding with billing
          </b-alert>
        </b-col>
      </b-row>

      <template v-else>

        <b-row align-h="between">
          <div  style="margin-bottom: 4px;"
            v-if="chargeSheetName">

            <b-button-group>

              <b-button
                v-b-tooltip.hover
                size="sm"
                title="Total amount Billed"
                :pressed="true"
                variant="dark"
              > Total
                Bill: <strong> KSH {{ formatPrice(total) }}</strong> </b-button>
              <b-button
                v-if="!patient_has_scheme || !patient_has_been_selected_scheme"
                v-b-tooltip.hover
                size="sm"
                title="Total available paid amount"
                :pressed="true"
                variant="Secondary"
              >
                Paid:<strong> KSH {{
                  formatPrice(total_patient_payments) }} </strong> </b-button>
              <b-button
                v-if="!patient_has_scheme || !patient_has_been_selected_scheme"
                v-b-tooltip.hover
                size="sm"
                title="Total balance to be paid"
                :pressed="true"
                variant="light"
              >
                Balance:<strong> KSH {{
                  formatPrice(chargesheettotal) }} </strong> </b-button>
              <b-button
                v-if="patient_has_scheme == true && patient_has_been_selected_scheme"
                v-model="schemes_shown"
                v-b-tooltip.hover
                v-b-toggle.collapse-1
                size="sm"
                title="Click to see scheme balances"
                :pressed="false"
                variant="Secondary"
                @click="schemes_shown = !schemes_shown"
              ><strong> Scheme
                Balances {{ schemes_shown ? '▲' : '▼' }} </strong> </b-button>

            </b-button-group>

          </div>

          <div>
            <b-button
              v-if="chargeSheetName && itemType != 'Service' && !needsPayment"
              v-show="!is_services_charge_sheet"
              size="sm"
              variant="primary"
              class="mr-1"
              :disabled="billing_in_progress"
              @click="showAddConsumableChargeDialog()"
            >
              Add Consumable
            </b-button>

            <b-button
              v-if="chargeSheetName && !needsPayment"
              size="sm"
              variant="warning"
              class="mr-1"
              :disabled="billing_in_progress"
              @click="showAddServiceChargeDialog()"
            >
              Add Service
            </b-button>

            <!-- <b-button size='sm'  size="sm" v-if="chargeSheetName && !needsPayment && this.values.length" @click="deleteSheet()" v-show="!billing_in_progress" class="ml-2" variant="danger">
              Delete All
            </b-button> -->

            <b-button
              v-if="chargeSheetName && !this.values.length"
              size="sm"
              :disabled="billing_in_progress"
              class="ml-1"
              variant="danger"
              @click="deleteChargeSheet()"
            >
              Cancel ChargeSheet
            </b-button>

            <b-popover
              ref="popover_confirm"
              target="popover-confirm-action"
              triggers="click"
              :show.sync="popoverShow"
              placement="auto"
              container="my-container"
              @show="onShowPopOver"
              @shown="onShownPopOver"
              @hidden="onHiddenPopOver"
            >
              <template #title>
                <b-button
                  size="sm"
                  class="close"
                  aria-label="Close"
                  @click="onClosePopOver"
                >
                  <span
                    class="d-inline-block"
                    aria-hidden="true"
                  >&times;</span>
                </b-button>
                {{ confirm_text }}
              </template>

              <div>

                <b-button
                  class="mr-1"
                  size="sm"
                  variant="danger"
                  @click="onClosePopOver"
                >
                  Cancel
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="submitSheet()"
                >
                  Confirm
                </b-button>
              </div>
            </b-popover>

            <b-button
              v-if="chargeSheetName || this.values.length"
              id="popover-confirm-action"
              size="sm"
              class="ml-1"
              variant="success"
              :disabled="values.length == 0 || billing_in_progress"
              @click="start_confirm('Continue ?')"
            >
              {{ billing_in_progress ? 'Billing in Progress...' : 'Bill Patient' }}
            </b-button>

          </div>

        </b-row>

        <b-row>
          <b-collapse
            v-if="patient_has_scheme && patient_has_been_selected_scheme && chargeSheetName"
            id="collapse-1"
            class="mt-2"
          >
            <b-card v-if="chargeSheetName">
              <span v-if="chargeSheetName" >
              <h4>Default Scheme:<strong> {{ default_scheme }}</strong></h4>
              </span>

              <span
                v-if="chargeSheetName"
                class="card-text"
              >
              <h5>Scheme Limits:</h5>
              </span>
              <b-button-group v-if="chargeSheetName">
                <b-button
                  v-if="patient_has_scheme
                    && patient_has_been_selected_scheme"
                  v-b-tooltip.hover
                  size="sm"
                  title="Available inpatient balance"
                  :pressed="true"
                  variant="light"
                > Inpatient Limit:<strong> KSH {{
                  formatPrice(scheme_inpatient_limit) }} </strong> </b-button>
                <b-button
                  v-if="patient_has_scheme
                    && patient_has_been_selected_scheme"
                  v-b-tooltip.hover
                  size="sm"
                  title="Available outpatient balance"
                  :pressed="true"
                  variant="Secondary"
                > Outpatient Limit:<strong>
                  KSH {{
                    formatPrice(scheme_outpatient_limit) }} </strong> </b-button>
              </b-button-group>

              <span
                v-if="chargeSheetName"
                class="card-text"
              >
              <h5>Scheme Balances:</h5>
              </span>
              <b-button-group v-if="chargeSheetName">
                <b-button
                  v-if="patient_has_scheme && patient_has_been_selected_scheme"
                  v-b-tooltip.hover
                  size="sm"
                  title="Available inpatient balance"
                  :pressed="true"
                  variant="light"
                > Inpatient
                  Balance:<strong> KSH {{ formatPrice(scheme_inpatient_amount) }} </strong> </b-button>
                <b-button
                  v-if="patient_has_scheme && patient_has_been_selected_scheme"
                  v-b-tooltip.hover
                  size="sm"
                  title="Available outpatient balance"
                  :pressed="true"
                  variant="Secondary"
                >
                  Outpatient
                  Balance:<strong> KSH {{ formatPrice(scheme_outpatient_amount) }} </strong> </b-button>
              </b-button-group>

            </b-card>
          </b-collapse>
        </b-row>

        <b-row class="w-100" v-if="!chargeSheetName && !is_admission_stalling && !Component_Loading">
          <b-button
            block
            variant="primary"
            @click="showCreateDialog()"
          >
            <i
              class="fa fa-plus-circle"
              aria-hidden="true"
            /> START BILLING
          </b-button>

        </b-row>
        <b-row v-if="!chargeSheetName && !is_admission_stalling && !Component_Loading">
          <MigrationMessage />
        </b-row>

        <b-row v-if="!chargeSheetName && is_admission_stalling">

          <div>
            <b-overlay
              :show="admission_stalling_overlay_shown"
              rounded="sm"
            >
              <b-jumbotron>
                <template #lead>
                  Patient admission status is {{ admission_status }}
                </template>

                <hr class="my-4">

                <p>
                  Patient admission status is {{ admission_status }} . Patient should be admitted first to proceed with
                  billing . Liase with records to finalize admission to disable this messege.If patient is not admitted
                  use the option below to resolve
                </p>

                <b-button
                  size="sm"
                  variant="primary"
                  @click="cancel_stalling()"
                >Resolve If Patient Is Not
                  Admitted</b-button>
                <!-- <b-button size='sm'  variant="success" href="#">Do Something Else</b-button>  -->
              </b-jumbotron>
            </b-overlay>
          </div>

        </b-row>

        <b-modal
          ref="my-modal"
          hide-footer
          title="Create Charge Sheet"
        >
          <b-container>

            <b-row>
              <h5 class="mt-1">
                Service / Consumbale
              </h5>
            </b-row>
            <b-row style="width: 100%">
              <b-form-radio-group
                id="radio-group-2"
                v-model="itemType"
                :aria-describedby="ariaDescribedby"
                buttons
                button-variant="outline-primary"
                style="margin-top: 10px; margin-top: 10px; height: 30px; width:100%"
                size="sm"
              >
                <b-form-radio value="Consumable">
                  CONSUMABLES & SERVICES
                </b-form-radio>
                <b-form-radio value="Service">
                  SERVICES ONLY
                </b-form-radio>
              </b-form-radio-group>
            </b-row>

            <b-row v-if="itemType == 'Consumable'">
              <h5 class="mt-1">
                Warehouse
              </h5>
            </b-row>
            <b-row
              v-if="itemType == 'Consumable'"
              class="w-100"
            >
              <user-warehouse
                style="width: 100% !important "
                @warehouse="setWarehouse"
              />
            </b-row>

            <b-row>
              <h5 class="mt-1">
                Type
              </h5>
            </b-row>
            <b-row style="width: 100%">
              <b-form-radio-group
                id="radio-group-2"
                v-model="section"
                :aria-describedby="ariaDescribedby"
                style="margin-top: 10px; margin-top: 10px; height: 30px; width:100%"
                buttons
                button-variant="outline-primary"
                size="sm"
                @change="onBillTypeChange($event)"
              >
                <b-form-radio value="Outpatient">
                  OUTPATIENT
                </b-form-radio>
                <b-form-radio
                  :disabled="is_patient_admitted"
                  value="Inpatient"
                >
                  INPATIENT
                </b-form-radio>
              </b-form-radio-group>
            </b-row>

            <b-row>
              <h5 class="mt-1">
                Section
              </h5>
            </b-row>
            <b-row style="width: 100%">
              <b-form-radio-group
                id="radio-group-2"
                v-model="wing"
                :aria-describedby="ariaDescribedby"
                buttons
                button-variant="outline-primary"
                style="margin-top: 10px; margin-top: 10px; height: 30px; width:100%"
                size="sm"
              >
                <b-form-radio :value="General_Wing_String">
                  GENERAL WING
                </b-form-radio>
                <b-form-radio :value="Private_Wing_String">
                  PRIVATE WING
                </b-form-radio>
              </b-form-radio-group>
            </b-row>

            <b-row>
              <h5 class="mt-1">
                Customer
              </h5>
            </b-row>

            <b-row style="width: 100%">
              <b-form-select
                v-model="selected_payment_mode"
                style="width: 100%"
                :options="payment_mode_options"
                value-field="payment_mode"
                text-field="payment_mode"
                @change="onPaymentModeChangeCreated($event)"
              />
            </b-row>

            <b-row
              style="width: 100%"
              class="mt-4"
            >
              <b-button
                size="sm"
                style="width: 100%"
                variant="success"
                @click="createSheet()"
              >
                <b>CREATE</b>
              </b-button>
            </b-row>

          </b-container>
        </b-modal>

        <b-modal
          ref="my-modal2"
          hide-footer
          :title="`Add Charge - (${this.warehouse})`"
        >
          <b-container>
            <b-row style="width: 100%">
              <h5> Search for item</h5>
            </b-row>

            <b-row
              v-if="filters.is_sales_item"
              style="width: 100%"
              class="mt-2"
            >
              <doctype-link
                v-if="showLink"
                style="width:100%"
                :doctype="'Item'"
                :rebuild="rebuild"
                :fieldname="'item'"
                :filters="filters"
                label="Search For Item"
                @data="checkCurrentItemPrice"
                :placeholder="'Start typing to search...'"
              />
            </b-row>
            <b-row>
              <b> {{ currentItem }}</b>
            </b-row>

            <b-row
              v-if="priceResponse && priceResponse.item_name && currentItem"
              style="width: 100%"
            >
              <b-table
                style="width: 100%"
                :items="[priceResponse].map(element => { return mapPrice(element) })"
                stacked="sm"
                :empty-text="`No items `"
                bordered
                small
                striped
                :empty-filtered-text="`No items`"
                head-row-variant="primary"
                :show-empty="true"
              />
            </b-row>

            <b-row
              v-if="(priceResponse.item_name && (priceResponse.available || !priceResponse.is_stock_item)) && currentItem"
              style="width: 100%"
            >
              <h5> Set quantity</h5>
            </b-row>
            <b-row
              v-if="(priceResponse.item_name && (priceResponse.available || !priceResponse.is_stock_item)) && currentItem"
              style="width: 100%"
            >
              <b-form-input
                v-model="currentQty"
                placeholder="Quantity"
                style="width: 100%"
                type="number"
              />
            </b-row>
            <b-row v-else>
              <span
                v-if="priceResponse.item_name && currentItem"
                class="text-danger"
              > * <b> {{ priceResponse.item_name
              }}</b> NOT
                AVAILABLE IN <b> {{ this.warehouse }}* </b> </span>
            </b-row>

            <b-row
              style="width: 100%"
              class="mt-4"
            >

              <b-button
                size="sm"
                style="width: 100%"
                variant="success"
                :disabled="(currentItem == null) || (this.currentQty == 0) || (priceResponse.is_stock_item == 1 && parseFloat(currentQty) > priceResponse.available) || !priceResponse.item_name || (priceResponse.is_stock_item == 1 && priceResponse.available == 0)"
                @click="addCustomServiceDialog({ item_code: priceResponse.item_code, qty: parseFloat(currentQty) }, priceResponse.is_stock_item)"
              ><b>ADD
                CHARGE</b> </b-button>
            </b-row>

          </b-container>
        </b-modal>

      </template>

      <b-row v-show="chargeSheetName">
        <hr style="width: 100%">
      </b-row>

      <b-row>
        <b-col style=" display: flex; align-self:left; justify-content: left; margin-left: -14px;">
          <user-warehouse
            v-if="false"
            v-show="!chargeSheetName"
            style="width: 30%;"
            @warehouse="setWarehouse"
          />
          <h5
            v-show="chargeSheetName"
            style="margin-top: 14px;"
          >[ {{ getTitle() }} ] <b-badge
            :variant="wing == 'General' ? 'success' : 'warning'"
          > <i
            class="fa fa-flag"
            aria-hidden="true"
          /> {{ wing
          }}
            WING</b-badge></h5>
        </b-col>

        <b-col style=" display: flex; align-self:right; justify-content: right; margin-right: -14px;">
          <h5
            v-show="chargeSheetName"
            style="margin-top: 15px;color:rgb(150, 150, 150) !important;"
          ><b class="d-none d-sm-block">  TYPE: </b>
          </h5>

          <b-form-radio-group
            v-show="chargeSheetName"
            id="radio-group-2"
            v-model="inpatient_outpatient"
            :aria-describedby="ariaDescribedby"
            buttons
            button-variant="outline-primary"
            style="margin-top: 10px; margin-right: 28px; margin-top: 10px; height: 30px; margin-left: 16px"
            size="sm"
            @change="onBillTypeChange($event)"
          >
            <b-form-radio
              value="Outpatient"
              @change="click_outpatient()"
            >
              OUTPATIENT
            </b-form-radio>
            <b-form-radio
              :disabled="is_patient_admitted"
              value="Inpatient"
              @change="click_inpatient()"
            >INPATIENT
              </b-form-radio>
          </b-form-radio-group>
        </b-col>

      </b-row>

      <b-row v-show="chargeSheetName">
        <hr style="width: 100%">
      </b-row>
      <b-row v-show="chargeSheetName">
        <h5><b>Payment Mode : {{ selected_payment_mode }}</b></h5>

        <b-form-select
          v-show="chargeSheetName"
          v-model="selected_payment_mode"
          :disabled="!patient_has_scheme || updating_payment_mode"
          style="width: 100%"
          :options="payment_mode_options"
          value-field="payment_mode"
          text-field="payment_mode"
          @change="onPaymentModeChange($event)"
        />
      </b-row>

      <b-row v-show="chargeSheetName">
        <hr style="width: 100%">
      </b-row>

      <b-row>

        <b-skeleton-table
          v-if="loading"
          v-show="!chargeSheetName"
          :rows="values.length"
          :columns="6"
          :table-props="{ bordered: true, striped: true }"
          :loading="loading"
          style="width: 100%"
        />

        <b-table
          v-else
          v-show="chargeSheetName && values.length > 0"
          style="width: 100%"
          responsive="sm"
          bordered
          stacked="sm"
          small
          striped
          :fields="fields"
          :items="values"
          :empty-text="`No items `"
          :empty-filtered-text="`No items`"
          head-row-variant="primary"
          :show-empty="true"
        >

          <template #cell(Actions)="data">
            <div class="text-center">

              <b-button
                v-if="data.item.Previous_Quantity != data.item.Quantity && !data.item.is_updating_qty"
                :disabled="data.item.is_updating_qty"
                style="margin-top: 6px;"
                size="sm"
                variant="outline-success"
                @click="data.item.is_updating_qty = !data.item.is_updating_qty; update_quantity_item(data.item)"
              >
                Confirm
              </b-button>
              <b-spinner
                v-if="data.item.is_updating_qty"
                variant="success"
                label="Update Spinner"
              />
              <b-spinner
                v-if="data.item.is_deleting"
                variant="danger"
                label="Deleting Spinner"
              />

              <b-button
                v-if="data.item.Previous_Quantity == data.item.Quantity && !data.item.is_deleting"
                size="sm"
                variant="outline-primary"
                :disabled="data.item.is_deleting"
                class="mx-1"
                @click="data.item.selected = !data.item.selected"
              ><i
                class="fa fa-edit"
              /></b-button>

              <b-button
                v-if="data.item.Previous_Quantity == data.item.Quantity && !data.item.is_deleting"
                size="sm"
                variant="outline-danger"
                :disabled="data.item.is_deleting"
                class="mx-0"
                @click="data.item.is_deleting = !data.item.is_deleting; removeItem(data.item)"
              ><i
                class="fa-solid fa-trash"
              /></b-button>
            </div>
          </template>

          <template #cell(Id)="data">
            <div class="text-center d-flex align-items-center justify-content-center">
              <strong style="margin-top: 9px;">
                {{ (data.index + 1) }}
              </strong>
            </div>
          </template>

          <template #cell(Item_Name)="data">
            <div class="text-left">
              <strong>{{ data.item.Item_Name }}</strong>
            </div>
            <div>( {{ data.item.Item_Code }} ) </div>

          </template>

          <template #cell(Price)="data">
            <div class="text-center d-flex align-items-center justify-content-center">
              <span style="margin-top: 9px;"> KSH <strong> {{ formatPrice(data.item.Price || 0) }} </strong> </span>
            </div>
          </template>

          <template #cell(Stock)="data">
            <div class="text-center d-flex align-items-center justify-content-center">
              <span style="margin-top: 9px;"> <strong> {{ data.item.Stock }} </strong> </span>
            </div>
          </template>

          <template #cell(Quantity)="data">
            <div
              class="text-center d-flex align-items-center justify-content-center"
              @click="data.item.selected = true"
            >
              <strong
                v-if="!data.item.selected"
                style="margin-top: 9px;"
              > {{ data.item.Quantity }} </strong>
              <b-form-input
                v-if="data.item.selected"
                v-model="data.item.Quantity"
                style="width: 130px; margin-top: 6px;"
                type="number"
                placeholder="Enter quantity"
                min="0"
                step="1"
                @input="restrictNumericInput"
              />
            </div>
          </template>

          <template #cell(Amount)="data">
            <div class="text-center d-flex align-items-center justify-content-center">
              <span style="margin-top: 9px;"> KSH <strong> {{ formatPrice(data.item.Amount || 0) }} </strong> </span>
            </div>
          </template>

          <template #cell(status)="data">
            <b-icon
              v-if="data.item && data.item.id"
              disabled
              icon="check-square"
              scale="2"
              variant="success"
            />
            <b-button
              v-else
              size="sm"
              variant="primary"
            >
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </b-button>
          </template>
        </b-table>

      </b-row>

    </b-container>
    <b-row style="margin-bottom: 75px;" />
  </div>
</template>

<script>
import {
  getItemPrice,
  createChargeSheet,
  chargeSheetSubmit,
  chargeSheetAddItem,
  chargeSheetDelete,
  updateChargeSheet,
  api,
  checkOpenAppointment,
} from './service'

import UserWarehouse from './components/UserWarehouse.vue'
import CreateInpatientAppointment from './components/CreateInpatientAppointment.vue'
import MigrationMessage from './components/MigrationMessage.vue'

export default {
  name: 'ChargeSheet',
  components: {
    UserWarehouse,
    CreateInpatientAppointment,
    MigrationMessage,
  },
  props: {
    patient: {
      default: '',
      type: String,
      required: true,
    },
    encounter: {
      type: Object,
      default: {},
      required: true,
    },
    serviceUnit: {
      type: String,
      default: '',
      required: true,
    },
    walkin: {
      type: Number,
      default: 0,
      required: false,
    },
    approval: {
      type: Number,
      default: 0,
      required: false,
    },
    inpatientRecord: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      rebuild: 0,
      currentItem: null,
      values: [],
      warehouse: '',
      stock_in_warehouse: 0,
      net_stock_in_warehouse: 0,
      currrent_billed_stock: 0,
      fields: ['Id', 'Item_Name', 'Quantity', 'Price', 'Amount', 'Stock', 'Actions'],
      needsApproval: false,
      chargeSheetName: null,
      patientHasAppointment: false,
      section: 'Outpatient',
      inpatient_outpatient: 'Outpatient',
      itemType: 'Consumable',
      is_services_charge_sheet: false,
      payment_mode_options: [],
      selected_payment_mode: '',
      selected_payment_mode_customer: '',
      // options: [],
      selected: null,
      wing: 'General Wing',
      loading: false,
      is_admission_stalling: false, // to prevent creation for stalling admissions
      cashPayerCustomer: null,
      defaultSettings: null,
      priceResponse: {},
      filters: {},
      currentQty: 1.00,
      showLink: true,
      needsPayment: false,
      admission_stalling_overlay_shown: false,

      Modal_Heading: '',
      Bill_services_String: 'Bill Services',
      Bill_Consumables_String: 'Bill Consumables',

      Private_Wing_String: 'Private Wing',
      General_Wing_String: 'General Wing',

      billing_in_progress: false,
      updating_payment_mode: false,
      adding_item_in_progress: false,

      schemes_shown: false,

      admission_status: '',
      latest_admission_document: '',
      scheme_status: '',
      patient_has_scheme: false,
      patient_has_been_selected_scheme: false,
      default_scheme: '',
      scheme_outpatient_amount: '',
      scheme_inpatient_amount: '',
      scheme_outpatient_limit: '',
      scheme_inpatient_limit: '',
      is_patient_admitted: true,

      response_message: '',
      showDismissibleAlert: false,
      showDismissibleAlertError: false,
      showDismissibleAlertSuccess: false,

      overlay_shown: false,

      chargesheettotal: 0,
      total_patient_payments: 0,
      total_chargesheet_amount: 0,

      confirm_text: 'Continue?',
      popoverShow: false,

      Add_Service_Consumable_Dialog: false,
      selected_Service: {},
      Service_amount: 1,

      selectable_services: [],
      selectable_services_pw: [],
      selectable_services_array: [],

      selectable_consumables: [],
      selectable_consumables_array: [],

      // paging
      currentPage: 1,
      pageSize: 14,

      charge_sheet_items: [],

      refreshKey: 0,
      options: [],
      Component_Loading: false,

      search: '',
      offset: 0,
      limit: 11,

      isShaking: false,

      prescriptions: [],
      prescription_fields: ['drug_name', 'instructions', 'status', 'action'],
      prescription_set: true,
      toggleSelect: false,
      defaultServicesWarehouse: '',

    }
  },

  computed: {
    total() {
      this.total_chargesheet_amount = Math.ceil(this.values.map(item => item.Amount || 0).reduce((a, b) => a + b, 0))
      return this.total_chargesheet_amount
    },
    resetBillingDialog() {
      const parent = this
      // console.log('resetting qty');
      parent.Service_amount = 1
    },
    dynamic_consumables() {
      const parent = this

      if (parent.patient_has_been_selected_scheme) {
        parent.selectable_consumables_array = []
        parent.selectable_consumables_array = parent.selectable_consumables.map(({
          Item_Name, Item_Code, Name, Item_Price, Price_Cash, Price_Cooprate, Is_Stock_Item, Description, ...var_rest
        }) => ({
          ...var_rest,
          value: Item_Name,
          Item_Name,
          label: `${Item_Name} ${Item_Code} - Price Corporate: ${parseFloat(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
          Selling_Price: parseFloat(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
          Selling_Price_Float: parseFloat(Price_Cooprate) || 0,
          Item_Code,
          Is_Stock_Item,
          Description,
        }))
        // console.log(parent.selectable_consumables_array);
        return parent.selectable_consumables_array
      }

      parent.selectable_consumables_array = []
      parent.selectable_consumables_array = parent.selectable_consumables.map(({
        Item_Name, Name, Item_Price, Item_Code, Price_Cash, Price_Cooprate, Is_Stock_Item, Description, ...var_rest
      }) => ({
        ...var_rest,
        Item_Name,
        value: Item_Name,
        label: `${Item_Name} ${Item_Code} - Price Cash: ${parseFloat(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
        Selling_Price: parseFloat(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
        Selling_Price_Float: parseFloat(Price_Cash) || 0,
        Item_Code,
        Is_Stock_Item,
        Description,
      }))
      // console.log(parent.selectable_consumables_array);
      return parent.selectable_consumables_array
    },
    dynamic_sevices() {
      const parent = this

      // console.log('selected wing is:');
      // console.log(parent.wing);

      if (parent.wing == parent.Private_Wing_String) {
        if (parent.patient_has_been_selected_scheme) {
          // console.log('patient selected as below:');
          // console.log(parent.patient_has_been_selected_scheme);
          parent.selectable_services_array = []
          parent.selectable_services_array = parent.selectable_services_pw.map(({
            Item_Name, Item_Code, Name, Item_Price, Price_Cash, Price_Cooprate, Is_Stock_Item, Description, ...var_rest
          }) => ({
            ...var_rest,
            Item_Name,
            value: Item_Name,
            // Item_Name:Item_Name,
            Price_Cash,
            Price_Cooprate,
            Item_Price: Price_Cooprate,
            label: `${Item_Name} ${Item_Code} - Price : ${parseFloat(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
            Selling_Price: parseFloat(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
            Selling_Price_Float: parseFloat(Price_Cooprate) || 0,
            Item_Code,
            Is_Stock_Item,
            Description,
          }))
          // console.log(parent.selectable_services_array);
          return parent.selectable_services_array
        }
        // console.log('patient selected as below:');
        // console.log(parent.patient_has_been_selected_scheme);
        parent.selectable_services_array = []
        parent.selectable_services_array = parent.selectable_services_pw.map(({
          Item_Name, Item_Code, Name, Item_Price, Price_Cash, Price_Cooprate, Is_Stock_Item, Description, ...var_rest
        }) => ({
          ...var_rest,
          Item_Name,
          value: Item_Name,
          // Item_Name:Item_Name,
          Price_Cash,
          Price_Cooprate,
          Item_Price: Price_Cash,
          label: `${Item_Name} ${Item_Code} - Price : ${parseFloat(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
          Selling_Price: parseFloat(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
          Selling_Price_Float: parseFloat(Price_Cash) || 0,
          Item_Code,
          Is_Stock_Item,
          Description,
        }))
        // console.log(parent.selectable_services_array);
        return parent.selectable_services_array
      }

      if (parent.patient_has_been_selected_scheme) {
        parent.selectable_services_array = []
        parent.selectable_services_array = parent.selectable_services.map(({
          Item_Name, Item_Code, Name, Item_Price, Price_Cash, Price_Cooprate, Is_Stock_Item, Description, ...var_rest
        }) => ({
          ...var_rest,
          // Item_Name:Item_Name,
          value: Item_Name,
          Item_Name,
          Price_Cash,
          Price_Cooprate,
          Item_Price: Price_Cooprate,
          label: `${Item_Name} ${Item_Code} - Price : ${parseFloat(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
          Selling_Price: parseFloat(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
          Selling_Price_Float: parseFloat(Price_Cooprate) || 0,
          Item_Code,
          Is_Stock_Item,
          Description,
        }))
        // console.log(parent.selectable_services_array);
        return parent.selectable_services_array
      }
      parent.selectable_services_array = []
      parent.selectable_services_array = parent.selectable_services.map(({
        Item_Name, Item_Code, Name, Item_Price, Price_Cash, Price_Cooprate, Is_Stock_Item, Description, ...var_rest
      }) => ({
        ...var_rest,
        // Item_Name:Item_Name,
        value: Item_Name,
        Item_Name,
        Price_Cash,
        Price_Cooprate,
        Item_Price: Price_Cash,
        label: `${Item_Name} ${Item_Code} - Price : ${parseFloat(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
        Selling_Price: parseFloat(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
        Selling_Price_Float: parseFloat(Price_Cash) || 0,
        Item_Code,
        Is_Stock_Item,
        Description,
      }))
      // console.log(parent.selectable_services_array);
      return parent.selectable_services_array
    },
    array_options() {
      const parent = this
      parent.options = []
      if (parent.Modal_Heading == parent.Bill_services_String) {
        parent.options = parent.dynamic_sevices
      } else if (parent.Modal_Heading == parent.Bill_Consumables_String) {
        parent.options = parent.dynamic_consumables
      }
      return parent.options
    },
    filtered() {
      // return this.array_options;
      return this.array_options.filter(item => item.label.toLowerCase().includes(this.search.toLowerCase()))
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length,
      )
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length,
      )
    },
    computed_page() {
      return (this.offset / this.limit) + 1
    },
    total_page() {
      return Math.ceil(this.filtered.length / this.limit)
    },
  },
  watch: {
    // whenever total changes
    total_chargesheet_amount(newtotal_chargesheet_amount, oldtotal_chargesheet_amount) {
      try {
        this.chargesheettotal = newtotal_chargesheet_amount - this.total_patient_payments
      } catch (error) {
        // console.log(error);
      }
    },
    patient() {
      this.init()
    },
  },
  beforeMount() {

  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.unsubscibe_payements()
  },
  created() {

  },

  methods: {
    init() {
      if (this.patient != '') {
        checkOpenAppointment(this.patient).then(res => {
          if (res === true) {
            this.patientHasAppointment = true
          }
        })
      }

      this.needsApproval = this.approval == 0
      this.checkIfChargeSheetExits()
      this.get_admission_status()

      this.get_payment_mode_options()
      this.get_services_mtrh()
      this.get_services_mtrh_pw()
      this.get_consumables_mtrh()
      // this.get_scheme_status();
      this.get_prescriptions()
      this.fetchDefaultWarehouse()
      this.subscibe_payements()
    },
    fetchDefaultWarehouse() {
      //
      api({
        method:
          'billing.billing.api.charge_sheet.actions.lab_test_bill.get_default_warehouse',
        args: {

        },
        freeze: false,
      }).then(r => {
        // alert(r)
        this.defaultServicesWarehouse = r
      })
    },
    formatDate(date) {
      return moment(date).format('Do MMM YYYY , h:mm:ss a')
    },
    formatPrice(value) {
      value = Math.ceil(value)
      value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return value
    },
    onSearch(query) {
      this.search = query
      this.offset = 0
    },
    printPrescription(Prescription) {
      console.log(Prescription)
      url_ = `${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Encounter')
      }&name=${encodeURIComponent(Prescription.parent)
      }&format=Encounter Prescription Print Format&_lang=en&trigger_print=1`
      console.log(url_)
      const w = window.open(frappe.urllib.get_full_url(url_))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    check_stocks() {
      const parent = this
      // console.log(parent.selected_Service.Item_Code);
      if (!parent.selected_Service.Item_Code) {
        // console.log('nothing here selected not checking stocks');
        return
      }

      if (parent.Modal_Heading == parent.Bill_services_String) {
        // console.log('service not checking stocks');
        return
      }

      const payloadDict = { ...parent.selected_Service, Patient: parent.patient, Ware_House: parent.warehouse }
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.check_stocks_advanced',
        args: {
          payload: payloadDict,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          parent.stock_in_warehouse = r.success.actual_quantity
          parent.net_stock_in_warehouse = r.success.net_quantity
          parent.currrent_billed_stock = r.success.current_billed_stock
        }
      })
    },
    filter_prescription(item) {
      console.log(item)
      this.onSearch(item.drug_name)
      return this.$nextTick(() => this.$refs.billingSelect.searchEl.focus())

      // this.$refs.billingSelect.open();
    },
    get_prescriptions() {
      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_doctors_medication_order',
        args: {
          patient: parent.patient,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          // console.log(r);
          if ('prescription' in r.success) {
            parent.prescriptions = r.success.prescription
            console.log(parent.prescriptions)
          }
        }
      })
    },
    add_item_charge_sheet() {
      const parent = this
      const service_added = parent.selected_Service
      const service_amount = { Quantity: parent.Service_amount }
      const charge_sheet_name = { Charge_Sheet_Name: parent.chargeSheetName }
      const ware_house = { Ware_House: parent.warehouse }
      const patient = { Patient: parent.patient }
      const { Is_Stock_Item } = parent.selected_Service

      const payloadDict = {
        ...service_added, ...service_amount, ...charge_sheet_name, ...ware_house, ...patient,
      }

      // console.log(payloadDict);
      if (payloadDict.Quantity == 0) {
        parent.response_message = 'Quantity is zero!'
        parent.makeVarToast('danger')
        return
      }

      const Item_Code_Array = parent.values.map(item => item.Item_Code)
      // console.log(Item_Code_Array);
      const item_name = payloadDict.Item_Code

      if (Item_Code_Array.includes(item_name)) {
        parent.response_message = 'Item already in the list, rather consider adjusting quantity from the list, thank you.'
        parent.makeVarToast('danger')
        return
      }

      // console.log('stock checks here');
      // console.log(parent.stock_in_warehouse);
      // console.log(service_amount.Quantity);
      // console.log(Is_Stock_Item);

      if ((parent.stock_in_warehouse < service_amount.Quantity) && (Is_Stock_Item == 1)) {
        const diff = service_amount.Quantity - parent.stock_in_warehouse
        parent.response_message = `Check Warehouse's Stocks are less than the requested quantity! ${diff} needed to continue! Please reconcile your stocks to continue`
        parent.makeVarToast('danger')
        parent.isShaking = false
        parent.isShaking = true
        setTimeout(() => {
          parent.isShaking = false
        }, 1300) // Adjust the duration as needed
        return
      }

      // return;

      parent.adding_item_in_progress = true

      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.add_service_to_charge_sheet',
        args: {
          payload: payloadDict,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          // console.log(r);
          parent.response_message = r.success.message
          parent.makeVarToast('success')

          // ["Id", "Item_Name" , "Item_Code", "Price","Quantity", "Amount", "in_stock", "Actions"],

          const jsonObject = r.success.response // JSON.parse(r.success.response);

          // console.log(jsonObject);

          parent.values.push({
            ...jsonObject, selected: false, is_updating_qty: false, is_deleting: false, Previous_Quantity: jsonObject.Quantity,
          })
          console.log(parent.values)

          parent.selected_Service = {}
          parent.Service_amount = 1
          parent.adding_item_in_progress = false

          // parent.set_items_array();
        }
      })
    },
    update_quantity_item(item) {
      const parent = this

      if (item.Quantity < 1) {
        parent.response_message = 'Item quantity is zero just consider removing it from list instead.'
        parent.makeVarToast('warning')
        item.is_updating_qty = false
        return
      }
      const Warehouse = parent.warehouse
      // console.log(Warehouse);
      const Patient = parent.patient
      // console.log(Patient);
      const Item_code = item.Item_Code
      // console.log(Item_code);

      const Is_Stock = item.Is_Stock_Item
      // console.log(Is_Stock);
      let method_to_run = ''
      let method_args = {}

      if (Is_Stock == 1 || Is_Stock == '1') {
        method_to_run = 'billing.billing.api.charge_sheet.actions.create_charge_sheet.update_quantity_stockable_sql'
        method_args = {
          item_name: item.Name,
          quantity: item.Quantity,
          price: item.Price,
          patient: Patient,
          warehouse: Warehouse,
          item_code: Item_code,
        }
      }

      if (Is_Stock == 0 || Is_Stock == '0') {
        method_to_run = 'billing.billing.api.charge_sheet.actions.create_charge_sheet.update_quantity_sql'
        method_args = {
          item_name: item.Name,
          quantity: item.Quantity,
          price: item.Price,
        }
      }

      item.is_updating_qty = true
      // console.log(item);
      // console.log(item.is_updating_qty);
      api({
        method:
          method_to_run,
        args: method_args,
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          // console.log(r);
          parent.response_message = r.success.message
          parent.makeVarToast('success')
          item.Previous_Quantity = item.Quantity
          item.Amount = r.success.new_amount
          item.selected = false
          item.is_updating_qty = false
          if ('stock_balance' in r.success) {
            item.Stock = r.success.stock_balance
          }

          // console.log(item);
          // console.log(item.is_updating_qty);
        }
      })
    },
    cancel_stalling() {
      const parent = this
      parent.admission_stalling_overlay_shown = true
      api({
        method:
          'billing.billing.api.charge_sheet.actions.inpatient.remove_inpatent_record',
        args: {
          name: parent.latest_admission_document,
        },
        freeze: false,
      }).then(r => {
        parent.response_message = r.success
        parent.makeVarToast('info')
        parent.admission_stalling_overlay_shown = false
      })
    },
    get_admission_status() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_admission_status',
        args: {
          patient: this.patient,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        parent.admission_status = r.admission_status
        parent.latest_admission_document = r.name
        // to allow scheduled billing
        if (parent.admission_status === 'Admitted' || parent.admission_status === 'Admission Scheduled') {
          this.is_patient_admitted = false
        }
        if (parent.admission_status === 'Cancelled Stalling' || parent.admission_status === 'Cancelled Stalling') {
          parent.is_admission_stalling = true
        }
      })
      // 10th july  cheruony
    },
    get_selected_payment_mode_options() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      // console.log('charge sheet name in get selected payment: ');
      // console.log(parent.chargeSheetName);
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.selected_payment_mode',
        args: {
          charge_sheet_name: parent.chargeSheetName,
          patient_no: parent.patient,
        },
        freeze: false,
      }).then(r => {
        // console.log('🦾🦾selected Payment option is: 🦾🦾');
        // console.log(r);
        parent.selected_payment_mode = r
        try {
          parent.get_customer_selected_on_create()
        } catch (Error) {
          // console.log(Error);
        }
      })
    },
    get_payment_mode_options() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.patient_payment_modes',
        args: {
          patient: this.patient,
        },
        freeze: false,
      }).then(r => {
        // console.log('Payment options are: ');
        // console.log(r);
        parent.payment_mode_options = r
        this.get_scheme_status()
      })
    },
    click_outpatient() {
      const parent = this
      // console.log('clicked outpatient!');
      const outpatient = 'Outpatient'
      parent.update_inpatient_outpatent(outpatient)
    },
    click_inpatient() {
      const parent = this
      // console.log('clicked inpatient!');
      const inpatient = 'Inpatient'
      parent.update_inpatient_outpatent(inpatient)
    },

    update_inpatient_outpatent(admission_stats) {
      const parent = this
      // console.log(parent.chargeSheetName);
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.update_inpatient_outpatient',
        args: {
          charge_sheet_name: parent.chargeSheetName,
          inp_out: admission_stats,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        parent.response_message = r.success
        parent.makeVarToast('info')
      })
    },
    onInpatientOutpatientChange() {
      // console.log('Selected Section:', this.section);

    },
    get_initial_inpatient_outpatent() {
      const parent = this

      if (parent.chargeSheetName !== undefined || parent.chargeSheetName !== '' || parent.chargeSheetName !== null) {
        api({
          method:
            'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_inpatient_or_outpatient',
          args: {
            charge_sheet_name: parent.chargeSheetName,
          },
          freeze: false,
        }).then(r => {
          // console.log('inpatient Outpatient: ');
          // console.log(r);
          if ('admission' in r) {
            parent.inpatient_outpatient = r.admission
            // console.log('inp 🫶🏾🐺🐺🫶🏾🐺🐺🫶🏾🐺🐺🫶🏾🐺🐺 out');
            // console.log(r.admission);
          }
        })
      }
    },
    get_customer_selected_on_create() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      // console.log('selected option for getting customer is: ');
      // console.log(this.selected_payment_mode);

      // console.log('PATIENT IS: ');
      // console.log(this.patient);

      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_selected_customer_charge_sheet',
        args: {
          patient_no: this.patient,
          customer_name: this.selected_payment_mode,
        },
        freeze: false,
      }).then(r => {
        // console.log('selected customer is: ');
        // console.log(r);
        parent.selected_payment_mode_customer = r.customer
        try {
          parent.get_initial_inpatient_outpatent()
        } catch (Error) {
          // console.log(Error);
        }
      })
    },
    onPaymentModeChange(event) {
      // console.log('select item changed');
      // console.log(this.selected_payment_mode);
      this.update_payment_mode()
    },
    onBillTypeChange(event) {
      // Handle the change event here
      // console.log('Selected Section:', this.section);
    },
    onPaymentModeChangeCreated(event) {
      this.get_customer_selected_on_create()
    },
    update_payment_mode() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      parent.updating_payment_mode = true
      // console.log('🔥🔥🔥 selected customer name🔥🔥🔥');
      // console.log(parent.selected_payment_mode);
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.update_customer_charge_sheet',
        args: {
          charge_sheet_name: parent.chargeSheetName,
          customer_name: parent.selected_payment_mode,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        if ('success' in r) {
          parent.response_message = r.success
          parent.makeVarToast('info')
          this.get_selected_scheme_payment_mode()
          parent.updating_payment_mode = false
        }
      })
    },
    get_selected_scheme_payment_mode() {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_charge_sheet_set_scheme',
        args: {
          charge_sheet_id: parent.chargeSheetName,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        // console.log('🔥🔥🔥 selected payer');
        if ('selected_payer' in r) {
          if (r.selected_payer == 'Cash Payer') {
            // console.log('🎃🎃🎃 selected cash payer')
            parent.patient_has_been_selected_scheme = false
          } else if (r.selected_payer == 'Scheme') {
            // console.log('🎃🎃🎃 selected scheme payer')
            parent.patient_has_been_selected_scheme = true
          }
        }
      })
    },
    get_scheme_status() {
      // billing.billing.api.charge_sheet.actions.create_charge_sheet.check_scheme_balance
      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.check_scheme_balance',
        args: {
          patient: this.patient,
        },
        freeze: false,
      }).then(r => {
        // console.log('schemes');
        // console.log(r);
        if ('no_scheme' in r) {
          parent.scheme_status = r.no_scheme
          parent.patient_has_scheme = false
          return
        }

        if ('patient_admission' in r) {
          parent.scheme_status = r.patient_admission
          parent.patient_has_scheme = true
          parent.scheme_outpatient_amount = r.outpatient_balance
          parent.scheme_inpatient_amount = r.inpatient_balance

          parent.scheme_outpatient_limit = r.outpatient_limit
          parent.scheme_inpatient_limit = r.inpatient_limit
          parent.default_scheme = r.default_scheme
          if (parent.chargeSheetName) {
            this.get_selected_scheme_payment_mode()
          }
        }

        // console.log(parent.patient_has_scheme);
      })
    },
    deleteChargeSheet() {
      this.$bvModal
        .msgBoxConfirm('Confirm charge sheet cancellation, This will cancel the charge sheet billing process, continue ?', {
          title: 'Cancel Charge Sheet',
          // size: 'mt-1',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            this.cancel_charge_sheet()
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    cancel_charge_sheet() {
      // billing.billing.api.charge_sheet.actions.create_charge_sheet.delete_charge_sheet
      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.delete_charge_sheet',
        args: {
          charge_sheet_id: this.chargeSheetName,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        if ('success' in r) {
          parent.response_message = r.success
          parent.makeVarToast('success')
          parent.chargeSheetName = null
          parent.values = []
          parent.loading = false
        }
      })
    },
    getTitle() {
      if (this.warehouse === this.defaultServicesWarehouse) // "All Warehouses - MTRH")
      {
        this.is_services_charge_sheet = true
        // console.log('Is services Charge sheet');
        // console.log(this.is_services_charge_sheet);
      }
      // return this.warehouse == "All Warehouses - MTRH" ?
      //   "Services Charges Sheet" : this.warehouse
      return this.warehouse === this.defaultServicesWarehouse
        ? 'Services Charges Sheet' : this.warehouse
    },
    mapPrice(data) {
      const result = {}
      result.item_name = data.item_name
      result.price = (data.price || 0).toFixed(2)
      if (data.is_stock_item) {
        result.available = data.available
      }
      return result
    },
    checkCurrentItemPrice(item_code) {
      this.currentItem = item_code
      this.rebuild += 1
      getItemPrice({
        item_code,
        patient: this.patient,
        warehouse: this.warehouse,
        customer: this.selected,
      }).then(response => {
        this.priceResponse = response
      })
    },
    showCreateDialog() {
      this.get_selected_payment_mode_options()

      this.$refs['my-modal'].show()
    },
    getFilters(is_stock_item) {
      if (this.wing == 'Private Wing' && this.itemType == 'Consumable') {
        this.filters = {
          is_stock_item, disabled: 0, has_variants: 0, is_sales_item: 1, is_private_wing: 1,
        }
      } else if (this.wing == 'Private Wing' && this.itemType == 'Service') {
        this.filters = {
          is_stock_item, disabled: 0, has_variants: 0, is_sales_item: 1, is_private_wing: 1,
        }
      } else if (this.wing == 'General Wing' && this.itemType == 'Consumable') {
        this.filters = {
          is_stock_item, disabled: 0, has_variants: 0, is_sales_item: 1, is_private_wing: 0,
        }
      } else if (this.wing == 'General Wing' && this.itemType == 'Service') {
        this.filters = {
          is_stock_item, disabled: 0, has_variants: 0, is_sales_item: 1, is_private_wing: 0,
        }
      } else {
        return this.filters = {}
      }
    },

    showModal(stockable) {
      this.currentItem = null
      this.previousItem = null
      this.getFilters(stockable)
      this.priceResponse = {}
      this.$refs['my-modal2'].show()
    },

    showNewServiceDialog() {
      this.showModal(0)
    },

    showNewConsumalableDialog() {
      this.showModal(1)
    },
    checkIfChargeSheetExits() {
      const parent = this
      parent.Component_Loading = true
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_charge_sheet_sql',
        args: {
          patient: parent.patient,
        },
        freeze: false,
      }).then(response => {
        if ('success' in response) {
          // console.log(response);
          parent.chargeSheetName = response.success[0].Charge_Sheet

          parent.needsApproval = false
          parent.warehouse = response.success[0].Warehouse
          parent.selected = response.success[0].Customer
          parent.wing = response.success[0].Wing
          parent.section = response.success[0].Type

          // parent.needsPayment = response.needs_payment;

          // console.log(response.success[0]);

          if (response.success[0].Charge_Sheet_Items.length > 0) {
            response.success[0].Charge_Sheet_Items.forEach((item_, index_) => {
              parent.values.push(item_)
            })

            parent.set_items_array()
          }

          parent.get_total_unsed_payments()
          parent.get_selected_payment_mode_options()
          parent.get_scheme_status()
          parent.Component_Loading = false
        }
        if ('none' in response) {
          // console.log('no charge sheet');
          parent.Component_Loading = false
        }
        parent.Component_Loading = false
      })
    },
    set_items_array() {
      const parent = this
      const var_values = parent.values
      parent.values = []
      parent.values = var_values.map(item => ({
        ...item,
        selected: false,
        is_updating_qty: false,
        is_deleting: false,
      }))

      parent.values.forEach(obj => {
        obj.Previous_Quantity = obj.Quantity
      })
      // console.log('table items');
      // console.log(parent.values);
    },

    submitChargeSheet() {
      chargeSheetSubmit({ name: this.chargeSheetName }).then(response => {
        this.values = []
      })
    },
    removeItem(data) {
      if (data) {
        const parent = this
        data.is_deleting = true
        api({
          method:
            'billing.billing.api.charge_sheet.actions.create_charge_sheet.remove_item_from_charge_sheet_sql',
          args: {
            item_name: data.Name,
            charge_sheet: parent.chargeSheetName,
          },
          freeze: false,
        }).then(r => {
          // console.log(r);
          if ('success' in r) {
            parent.response_message = r.success.message
            parent.makeVarToast('success')
            const { item_name } = r.success
            parent.removeItemByField(parent.values, 'Name', item_name)
          }
        })
      }
    },
    removeItemByField(list, field, value) {
      const index = list.findIndex(item => item[field] === value)
      if (index !== -1) {
        list.splice(index, 1)
      }
    },
    setWarehouse(wh) {
      this.warehouse = wh
    },
    showAddChargeDialog() {
      const context = this
      const x = new frappe.ui.Dialog({
        title: 'Add Charge',
        fields: [
          {
            label: 'Item',
            fieldname: 'item_code',
            fieldtype: 'Link',
            options: 'Item',
            reqd: true,
            filters: {
              is_stock_item: 1, disabled: 0, has_variants: 0, is_sales_item: 1, is_private_wing: context.wing == 'Private', exclude_from_charge_sheet: 0,
            },
          },
          {
            label: 'Quantity',
            fieldname: 'qty',
            fieldtype: 'Float',
            default: 1,
            reqd: true,
          },
        ],
        primary_action_label: 'Add Item',
        primary_action: values => {
          const available = this.values.filter(item => item.item_code == values.item_code).length

          if (available) {
            this.response_message = 'Item already in the list, rather consider adjusting quantity from the list, thank you.'
            this.makeToastVar('danger', 'Duplicates')
          } else {
            context.values.push(values)
            context.getPrice(values.item_code, true)
            x.hide()
            this.showAddChargeDialog()
          }
        },
        secondary_action_label: 'Cancel',
        secondary_action: values => {
          x.hide()
        },
      })
      x.show()
    },

    add_items_to_list() {
      const available = ''
      const context = this
      if (available) {
        this.response_message = 'Item already in the list, rather consider adjusting quantity from the list, thank you.'
        this.makeToastVar('danger', 'Duplicates')
      } else {
        context.values.push(values)
        context.getPrice(values.item_code, true)
        x.hide()
        this.showAddChargeDialog()
      }
    },

    restrictNumericInput(event) {
      // Remove any non-digit characters from the input value
      // console.log(event);
      // this.Service_amount = this.Service_amount.replace(/[^0-9]/g, '');
      // this.Service_amount = this.Service_amount.replace(/\D/g, "");
      // //console.log(this.Service_amount);
    },

    get_services_mtrh() {
      const parent = this

      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_services_from_charges',
        args: {
          is_private_wing: 0,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          // console.log('services  loaded');
          parent.selectable_services = []
          parent.selectable_services = r.success
          // parent.set_sevices();
          // console.log( parent.selectable_services);
        }
      })
    },

    get_services_mtrh_pw() {
      const parent = this

      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_services_from_charges',
        args: {
          is_private_wing: 1,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          // console.log('services pw loaded');
          parent.selectable_services_pw = []
          parent.selectable_services_pw = r.success
          // parent.set_sevices();
          // console.log( parent.selectable_services_pw);
        }
      })
    },

    get_consumables_mtrh() {
      const parent = this

      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_consumables_from_charges_all',
        args: {
          is_private_wing: parent.wing == 'Private' ? 1 : 0,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          // console.log('items loaded');
          parent.selectable_consumables = []
          parent.selectable_consumables = r.success
          parent.set_consumables()
        }
      })
    },

    set_sevices() {
      // let parent = this;
      // parent.selectable_services_array = parent.selectable_services.map(({ Item_Name,Name,Item_Price,Price_Cash,Price_Cooprate, ...var_rest }) => ({
      //   ...var_rest,
      //   value: Item_Name,
      //   label: Item_Name +' - Price : '+parseFloat(Item_Price).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }) ,
      //   Selling_Price:parseFloat(Item_Price).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
      //   Selling_Price_Float:parseFloat(Item_Price)
      // }));
      // //console.log(parent.selectable_services_array);
    },

    set_consumables() {
      const parent = this
      parent.selectable_consumables_array = parent.selectable_consumables.map(({
        Item_Name, Name, Item_Price, Price_Cash, Price_Cooprate, ...var_rest
      }) => ({
        ...var_rest,
        value: Item_Name,
        label: `${Item_Name} - Price Cash: ${parseInt(Price_Cash).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })} Price Corporate: ${parseInt(Price_Cooprate).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
        Selling_Price: parseInt(Item_Price).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
        Selling_Price_Float: parseFloat(Item_Price),
      }))
      // console.log(parent.selectable_services_array);
    },

    onChangeService(event) {
      const parent = this
      // empty search
      parent.onSearch('')
      if (event == null || event == '' || event == undefined) {
        parent.selected_Service = {}
      }

      // console.log('changed!!');
      // console.log(event);
      // this.selected_Service = event;
      // console.log(parent.selected_Service);
      if (event != null || event != '' || event != undefined) {
        parent.check_stocks()
      }
    },

    // services section
    showAddServiceChargeDialog() {
      const context = this
      context.selected_Service = {}
      context.Modal_Heading = context.Bill_services_String
      context.Add_Service_Consumable_Dialog = true
    },
    // consumables section
    showAddConsumableChargeDialog() {
      const context = this
      context.selected_Service = {}
      context.Modal_Heading = context.Bill_Consumables_String
      context.Add_Service_Consumable_Dialog = true
    },
    addCustomServiceDialog(values, is_stock_item) {
      const context = this
      const available = this.values.filter(item => item.item_code == values.item_code).length

      if (available) {
        this.response_message = 'Item already in the list, rather consider adjusting quantity from the list, thank you.'
        this.makeToastVar('danger', 'Duplictaes')
      } else {
        context.values.push(values)
        context.getPrice(values.item_code, true)
        this.showLink = false
        this.showLink = true
        this.currentItem = null
        this.currentQty = 1
        this.showModal(is_stock_item)
      }
    },

    showUpdateDialog(item_code, qty, in_stock) {
      const context = this
      const x = new frappe.ui.Dialog({
        title: 'Update Quantity',
        fields: [
          {
            label: 'Item',
            fieldname: 'item_code',
            fieldtype: 'Link',
            options: 'Item',
            default: item_code,
            read_only: true,
            reqd: true,
            filters: {
              item_code, is_stock_item: 0, disabled: 0, has_variants: 0, is_sales_item: 1, exclude_from_charge_sheet: 0,
            },
          },
          {
            label: 'Quantity',
            fieldname: 'qty',
            fieldtype: 'Float',
            default: qty,
            reqd: true,
          },
        ],
        primary_action_label: 'Post',
        primary_action: data => {
          if (data.qty > in_stock && in_stock !== 0) {
            this.show_alert('Error value greater that in stock')
            return
          }
          context.updateQty(data)
          x.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action: values => {
          x.hide()
        },
      })
      x.show()
    },
    callCreateSheetApi() {
      // console.log(this.selected_payment_mode);
      const needs_approval = this.needsApproval ? 1 : 0

      const chargeSheet = {
        patient: this.patient,
        encounter: this.encounter ? this.encounter.name : '',
        warehouse: this.warehouse,
        is_inpatient_bill: this.section == 'Outpatient' ? 0 : 1,
        customer: this.selected_payment_mode_customer,
        is_private_wing: this.wing == this.Private_Wing_String ? 1 : 0,
        doctype: 'Charge Sheet',
        needs_approval,
      }
      // console.log(chargeSheet);

      const parent = this
      api({
        method:
          'billing.billing.api.charge_sheet.actions.create_charge_sheet.create_charge_sheet_sql',
        args: {
          payload: chargeSheet,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        parent.response_message = r.success.message
        parent.makeVarToast('success')

        parent.chargeSheetName = r.success.charge_sheet_name
        parent.section = r.success.bill_type

        parent.chargesheettotal = r
        parent.get_selected_scheme_payment_mode()
        parent.get_initial_inpatient_outpatent()
        // refresh div
        parent.refreshKey += 1
        // console.log(parent.chargesheettotal);
      })
    },
    // makeToast(variant = 'info') {
    //   this.$bvToast.toast("Bill recorded", {
    //     title: `Success`,
    //     variant: variant,
    //     solid: true,
    //   });
    // },
    makeVarToast(variant = null) {
      this.$bvToast.toast(this.response_message, {
        title: 'BILLING INFORMATION',
        variant,
        solid: true,
      })
    },
    setDefaultWarehouse() {
      const company = frappe.defaults.get_user_default('Company')
      const aabr = ''
      this.docGetValue('Company', company, 'abbr').then(result => {
        console.log(result.message)
      })
    },
    createSheet() {
      // this.setDefaultWarehouse();
      if (this.itemType == 'Service') {
        this.warehouse = this.defaultServicesWarehouse// "All Warehouses - MTRH"
        this.is_services_charge_sheet = true
      } else {
        this.is_services_charge_sheet = false
      }

      if (!this.warehouse) {
        this.response_message = 'Warehouse is required'
        this.makeVarToast('danger')
        return
      }

      if (!this.selected_payment_mode) {
        this.response_message = 'Payment Mode for customer is required'
        this.makeVarToast('danger')
        return
      }

      this.$refs['my-modal'].hide()

      this.boxOne = ''

      this.$bvModal
        .msgBoxConfirm('Confirm charge sheet creation', {
          title: `${this.section} Charge Sheet`,
          size: 'mt-1',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes, proceed',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            this.callCreateSheetApi()
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    get_current_balance() {
      // console.log(this.chargeSheetName);
      const parent = this
      api({
        method:
          'billing.billing.api.payment_integration.process_payment.get_charge_sheet_payment_status',
        args: {
          charge_sheet: this.chargeSheetName,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        parent.chargesheettotal = r
        // console.log(parent.chargesheettotal);
      })
    },
    get_total_unsed_payments() {
      // console.log(this.chargeSheetName);
      const parent = this
      api({
        method:
          'billing.billing.api.payment_integration.process_payment.get_unallocated_payment',
        args: {
          patient: this.patient,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        parent.total_patient_payments = r
        // console.log('total patient payments');
        // console.log(parent.total_patient_payments);
      })
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.response_message, {
        title: 'Mpesa',
        variant,
        solid: true,
      })
    },
    makeToastVar(variant = null, title = null) {
      this.$bvToast.toast(this.response_message, {
        title,
        variant,
        solid: true,
      })
    },
    subscibe_payements() {
      // console.log('subscribed');
      const parent = this
      const subscription_channel = `${this.patient}_payments_listener`
      // console.log('subscibed to '+subscription_channel);
      // subscribe to channel
      window.socket.on(subscription_channel, data => {
        // this.show_alert({  message: data.body,indicator: 'green' }, 55);
        parent.response_message = data.body
        if (data.title === 'Payment Made') {
          parent.makeToastVar('info', 'Payment')
        }
      })
    },
    unsubscibe_payements() {
      const subscription_channel = `${this.patient}_payments_listener`
      window.socket.off(subscription_channel)
      // console.log('unsubscribed');
    },

    get_items_services() {

    },

    onShowPopOver() {
      // Handle popover show event
    },
    onShownPopOver() {
      // Handle popover shown event
    },
    onHiddenPopOver() {
      // Handle popover hidden event
    },
    onClosePopOver() {
      // Handle close button click
      this.popoverShow = false
    },

    start_confirm(messege) {
      const parent = this
      parent.confirm_text = messege
      parent.popoverShow = true
      // console.log('clicked');
      // console.log(parent.popoverShow);
    },

    submitSheet() {
      const parent = this
      parent.popoverShow = false

      parent.billing_in_progress = true
      parent.showDismissibleAlert = false
      parent.showDismissibleAlertSuccess = false
      parent.showDismissibleAlertError = false
      // console.log(this.chargeSheetName);
      api({
        method:
          'billing.billing.api.payment_integration.process_payment.submit_chargesheet',
        args: {
          name: this.chargeSheetName,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        // process stalled payment
        if ('Need_Payment' in r) {
          parent.response_message = r.Need_Payment
          parent.makeVarToast('danger')
          parent.showDismissibleAlert = false
          parent.showDismissibleAlertSuccess = false

          parent.showDismissibleAlertError = true
          parent.billing_in_progress = false
        }

        if ('Not_Equal' in r) {
          parent.response_message = r.Not_Equal
          parent.makeVarToast('danger')

          parent.showDismissibleAlertSuccess = false
          parent.showDismissibleAlertError = true
          parent.showDismissibleAlert = false
          parent.billing_in_progress = false
        }

        if ('Submitted' in r) {
          parent.chargeSheetName = null
          parent.values = []
          parent.response_message = r.Submitted
          parent.makeVarToast('success')
          parent.showDismissibleAlert = false

          parent.showDismissibleAlertError = false
          parent.showDismissibleAlertSuccess = false
          parent.billing_in_progress = false
        }
      })
    },
    deleteSheet() {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            this.loading = true
            chargeSheetDelete(this.chargeSheetName).then(response => {
              this.chargeSheetName = null
              this.values = []
              this.loading = false
            })
          }
        })
        .catch(err => {
          this.loading = false
          // An error occurred
        })
    },
    getPrice(item_code, notPreloaded, item_id) {
      let items = []
      getItemPrice({
        item_code,
        patient: this.patient,
        warehouse: this.warehouse,
        customer: this.selected,
      }).then(response => {
        this.values.forEach((element, index) => {
          if (element.item_code == item_code) {
            // console.log(element)
            items.push({
              item_code,
              qty: element.qty,
              item_name: response.item_name,
              price: response.price,
              amount: element.qty * response.price,
              in_stock: response.available,
              status: notPreloaded ? 'Not saved' : 'Saved',
              id: item_id,
            })
            if (notPreloaded) {
              if (element.qty > response.available && response.is_stock_item) {
                this.msgprint(`${item_code} Insufficient stocks`)
                items = items.filter(me => me.item_code != element.item_code)
              } else {
                const is_inpatient_bill = parent.inpatient_outpatient == 'Outpatient' ? 0 : 1
                const amount = element.qty * response.price
                this.addItem({
                  is_inpatient_bill, item_code: element.item_code, qty: element.qty, amount, charge_sheet: this.chargeSheetName, item_name: response.item_name,
                })
              }
            }
          } else {
            items.push(element)
          }
        })

        this.values = items
      })
    },
    addItem(payload) {
      chargeSheetAddItem(payload).then(res => {
        this.response_message = 'Item added Successfully'
        this.makeToastVar('Secondary', 'Added!')
        const items = []
        this.values.forEach((element, index) => {
          if (payload.item_code == element.item_code) {
            items.push({
              item_code: element.item_code,
              qty: element.qty,
              price: element.price,
              amount: element.amount,
              item_name: payload.item_name,
              in_stock: element.in_stock,
              status: 'Saved',
              id: res,
            })
          } else {
            items.push(element)
          }
        })

        this.values = items
      })
    },

    updateQty(payload) {
      payload.name = this.chargeSheetName
      updateChargeSheet(payload).then(result => {
        this.values.forEach((item, index) => {
          if (item.item_code === payload.item_code) {
            this.values[index].qty = payload.qty
            this.values[index].amount = this.values[index].price * payload.qty
          }
        })

        // this.total=  Math.ceil(this.values.map((item) => {return item.amount || 0;}).reduce((a, b) => a + b, 0));
        this.show_alert('Success Quantity  Updated')
      })
    },
    loadCustomer() {
      frappe.db.count('Inpatient Record', {
        filters: { patient: this.patient, status: 'Admitted' },
      }).then(exists => {
        if (exists) {
          this.section = 'Inpatient'
        }
      })

      this.docGetValue('Patient', this.patient, 'customer').then(result => {
        if (result.message && result.message.customer) {
          // console.log("Customer")
          // console.log(result.message.customer)
          this.options.push({ value: result.message.customer, text: 'Cash Payer' })
          this.cashPayerCustomer = result.message.customer
        }
      })

      this.docGetList('Customer Insurance Schemes', { filters: { parent: this.patient }, fields: ['principal_member', 'scheme'] }).then(results => {
        if (results && results.length) {
          results.forEach(item => {
            this.docGetValue('Customer Group', item.scheme, 'default_customer').then(result => {
              this.options.push({ value: result.message.default_customer, text: item.scheme })
            })
          })

          const item = results[0]
        }
      })

      this.docGetValue('Patient', this.patient, 'customer_group').then(result => {
        if (result.message && result.message.customer_group) {
          if (result.message.customer_group == 'All Customer Groups') {
            this.selected = this.cashPayerCustomer
            return
          }

          this.docGetValue('Customer Group', result.message.customer_group, 'default_customer').then(result1 => {
            const count = this.options.find(element => element.text == result.message.customer_group)

            if (!count) {
              this.options.push({ value: result1.message.default_customer, text: result.message.customer_group })
            }

            this.selected = result1.message.default_customer
          })
        }
      })
    },
  },
}
</script>

<style>
.custom-modal-width .modal-dialog {
  width: 80%;
}

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.box {
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ccc;
  padding: 10px;
}

.heading {
  position: absolute;
  top: -16px;
  left: 20%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px;
  border: 1px solid rgb(255, 255, 255);
}


.shake {
  animation: shake 0.5s;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
</style>
