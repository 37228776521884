<template>
  <div
    v-show="false"
    style="width: 100% !important;"
    class="migration-info"
  >
    <b-row>
      <b-col /> <h4 class="">
        UPDATE NOTIFICATION!!
      </h4><b-col />
    </b-row>
    <div class="info-text">
      <p><b>As of 15th August 2023</b>, We are migrating from using Sales Invoice Document to using the simplified Patient Bill document</p>
      <p class="highlight">
        Updates: Eliminate slow process of generating bills and simplify split, merge, and submit operations.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MigrationInfo',
}
</script>

  <style scoped>
  .migration-info {
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 20px auto;
    max-width: 700px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }

  .info-text {
    font-size: 16px;
    line-height: 1.5;
  }

  .highlight {
    font-weight: bold;
    color: #007bff;
  }

  @keyframes pulse-glow {
    0%, 100% {
      transform: scale(1);
      opacity: 0.6;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }

  .migration-info::before {
    content: "";
    position: absolute;
    top: -20%;
    left: -20%;
    width: 140%;
    height: 140%;
    background: radial-gradient(circle, rgba(0, 123, 255, 0.5) 10%, transparent 70%);
    animation: pulse-glow 4s infinite;
    z-index: -1;
    opacity: 0.6;
    border-radius: 50%;
    transform: scale(1);
  }
  </style>
